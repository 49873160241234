import styled from "styled-components";
import { SectionWithVisualPropsType } from "./SectionWithVisualProps";

interface SectionWithVisualPropsStyle {
  type: SectionWithVisualPropsType;
}

export const SectionWithVisualPropsStyle = styled.section<SectionWithVisualPropsStyle>`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  flex-direction: ${({type}) => type === SectionWithVisualPropsType.left ? "row" : "row-reverse"};

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  img {
    width: 100%;
  }

  .content {
    max-width: 475px;
    width: 100%;

    h3 {
      margin-bottom: 20px;
    }
  }
`;