import { Footer, Header } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { Section } from "../../components/common";
import { CheckboxFieldStyle } from "../../components/common/CheckboxField/CheckboxField.style";
import { PrivacyPolicyStyle } from "../PrivacyPolicy/PrivacyPolicy.style";
import { DisclaimerStyle } from "./Disclaimer.style";

const Disclaimer = () => {
  return (
    <PrivacyPolicyStyle>
      <DisclaimerStyle>
        <Header
          heading="Stay Connected with Addi Auto Transport Ltd"
          image="/images/banners/terms-and-conditions.png"
          type={HeaderType.inner}
        />

        <Section className="testimonial-wrapper">
          <p>
            We value our customers and want to keep you informed with the latest
            updates, exclusive offers, and important notifications. By
            subscribing to our messaging service, you can receive all these
            directly to your phone. Simply check the box below to opt-in.
          </p>

          <p className="agree-wrapper">
            <CheckboxFieldStyle name="agreeToTerms" /> I agree to receive
            recurring messages from Addi Auto Transport Ltd.
          </p>

          <h3>Consent Disclosure:</h3>
          <p>
            By checking this box, you agree to receive recurring messages from
            Addi Auto Transport Ltd. Reply STOP to opt out; Reply HELP for help.
            Message frequency varies; Message and data rates may apply; Carriers
            are not liable for delayed or undelivered messages. For assistance,
            contact us at Email:{" "}
            <a href="mailto:support@addiautotransport.com">
              support@addiautotransport.com
            </a>{" "}
            or call{" "}
            <a href="tel:+16107856302" target="__blank">
              +1(346)-459-6422
            </a>
            .
          </p>

          <h3>How It Works:</h3>
          <ol>
            <li>
              <b>Subscribe:</b> 1. Opt-in by checking the box and providing your
              phone number.
            </li>
            <li>
              <b>Receive Messages</b> Stay updated with periodic messages
              tailored to your interests.
            </li>
            <li>
              <b>Manage Subscription</b> You can stop receiving messages at any
              time by replying STOP. For assistance, reply HELP on{" "}
              <a href="tel:+16107856302" target="__blank">
                +1(346)-459-6422
              </a>{" "}
              or contact us at{" "}
              <a href="mailto:support@addiautotransport.com">
                support@addiautotransport.com
              </a>
            </li>
          </ol>
        </Section>

        <Footer />
      </DisclaimerStyle>
    </PrivacyPolicyStyle>
  );
};

export default Disclaimer;
