import { Footer, Header, SectionWithVisual } from "../../components";
import { SectionWithVisualProps } from "../../components/SectionWithVisualProps";
import { HeaderType } from "../../components/Header/Header";
import { SectionWithVisualType } from "../../components/SectionWithVisual/SectionWithVisual";
import { SectionWithVisualPropsType } from "../../components/SectionWithVisualProps/SectionWithVisualProps";
import { SectionType } from "../../components/common/Section/Section";
import { DoorToDoorStyle } from "./DoorToDoor.style";

const DoorToDoor = () => {
  return (
    <DoorToDoorStyle>
      <Header
        type={HeaderType.inner}
        heading="Door to Door Auto Transport"
        image="/images/banners/services.png"
      />

<SectionWithVisualProps
  heading="What is Door to Door Car Shipping?"
  description={
    <div>
      <h2>Overview of Door to Door Car Shipping Services</h2>
      <p>
        Door to door car shipping is a convenient transportation method where your vehicle is picked up and delivered directly to your specified locations. Unlike terminal-to-terminal shipping, which requires you to drop off and pick up your car at designated facilities, door to door services streamline the process, making it easier for you.
      </p>

      <h3>How It Works: Step-by-Step Process</h3>
      <p>The process of door to door car shipping is straightforward:</p>
      <ol>
        <li><strong>Consultation:</strong> You contact a car shipping company for an initial consultation.</li>
        <li><strong>Quote:</strong> A customized quote is provided based on your specific needs.</li>
        <li><strong>Scheduling:</strong> A pick-up date is set at your location.</li>
        <li><strong>Pick-Up:</strong> A carrier arrives at your home or specified location to load your vehicle.</li>
        <li><strong>Transit:</strong> Your vehicle is securely transported to the destination.</li>
        <li><strong>Delivery:</strong> The carrier delivers your vehicle directly to the specified location.</li>
      </ol>
    </div>
  }
  image="/images/services/door-to-door-shipping.png" 
  type={SectionWithVisualPropsType.left} 
  sectionType={SectionType.light} 
/>




<SectionWithVisualProps
  heading="Benefits of Door to Door Car Shipping"
  description={
    <div>
      <h4>Convenience of Home Pick-Up and Delivery</h4>
      <p>
        One of the main advantages of door to door transport is the unparalleled convenience it offers. You don’t need to worry about how to get your vehicle to a terminal or back home after dropping it off.
      </p>

      <h4>Time-Saving Advantages</h4>
      <p>
        Door to door auto transport saves you valuable time. You avoid long waits and unnecessary travel, allowing you to focus on other important tasks.
      </p>

      <h4>Reduced Hassle Compared to Terminal-to-Terminal Shipping</h4>
      <p>
        With door to door vehicle transport, you skip the hassle of navigating terminal locations and managing vehicle drop-offs and pick-ups. This ease of access makes the entire process smoother.
      </p>

      <h4>Enhanced Vehicle Protection During Transit</h4>
      <p>
        Your vehicle is handled carefully from pick-up to delivery, reducing the risk of damage. Many companies also offer enclosed transport options for added protection.
      </p>
    </div>
  }
  image="/images/services/door-to-door-shipping.png"
  type={SectionWithVisualPropsType.right} 
  sectionType={SectionType.light} 
/>


<SectionWithVisualProps
  heading="Our Door to Door Car Shipping Process"
  description={
    <div>
      <h4>Initial Consultation and Quote</h4>
      <p>
        Our process begins with an initial consultation, where we discuss your requirements. Based on this, we provide a transparent quote that covers all aspects of the service.
      </p>

      <h4>Scheduling Pick-Up and Delivery</h4>
      <p>
        Once you accept the quote, we schedule a convenient pick-up time that fits your schedule.
      </p>

      <h4>Vehicle Inspection and Documentation</h4>
      <p>
        Before loading your vehicle, we conduct a thorough inspection and complete necessary documentation to ensure everything is recorded.
      </p>

      <h4>Transportation and Tracking Options</h4>
      <p>
        We offer real-time tracking options, so you can monitor your vehicle's journey from pick-up to delivery.
      </p>

      <h4>Delivery Confirmation and Follow-Up</h4>
      <p>
        After delivery, we confirm the safe arrival of your vehicle and follow up to ensure your satisfaction with our service.
      </p>
    </div>
  }
  image="/images/services/door-to-door-shipping.png" 
  type={SectionWithVisualPropsType.left} 
  sectionType={SectionType.light} 
/>


<SectionWithVisualProps
  heading="Why Choose Us for Your Door to Door Car Shipping?"
  description={
    <div>
      <h4>Experience and Expertise in the Industry</h4>
      <p>
        With over 7 years of experience in door to door transportation, our expertise ensures a reliable and efficient shipping process.
      </p>

      <h4>Safety Measures and Insurance Coverage</h4>
      <p>
        We prioritize the safety of your vehicle with robust safety measures and comprehensive insurance coverage for peace of mind.
      </p>

      <h4>Customer Testimonials and Success Stories</h4>
      <p>
        Our satisfied customers speak volumes about our commitment to quality service. We encourage you to read our testimonials and success stories to see the difference we make.
      </p>

      <h4>Competitive Pricing and Transparent Quotes</h4>
      <p>
        We believe in fair pricing without hidden fees. Our transparent quotes ensure you know exactly what to expect, making door to door car shipping an economical choice.
      </p>
      <br></br>
<hr></hr>
      
      <p>
        We believe in fair pricing without hidden fees. Our transparent quotes ensure you know exactly what to expect, making door to door car shipping an economical choice.
      </p>
      
    </div>
  }
  image="/images/services/door-to-door-shipping.png" 
  type={SectionWithVisualPropsType.right} 
  sectionType={SectionType.light} 
/>

      <Footer />
    </DoorToDoorStyle>
  );
};

export default DoorToDoor;
