import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";

import { QuotationFormStyle, StepsStyle } from "./QuotationForm.style";
import Step1 from "./Steps/Step1";
import { Button } from "../common";
import { ButtonType } from "../common/Button/Button";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import FinalStep from "./Steps/FinalStep";

const LAST_STEP = 3;

const items = [
  {
    title: "",
  },
  {
    title: "",
  },
  {
    title: "",
  },
];

// type FormValues = {
//   from: string
//   to: string
//   date: string
//   transportType: string
// }

const QuotationForm = () => {
  const [step, setStep] = useState(0);

  const methods = useForm({
    defaultValues: {
      from: "",
      to: "",
      date: "",
      transportType: "",
      make: "",
      model: "",
      year: "",
      name: "",
      email: "",
      phone: "",
      terms: true,
    },
  });

  const handleFormSubmit = (values: any) => {
    if (step !== LAST_STEP - 1) {
      setStep(step + 1);
      return;
    }

    axios
      .post(
        `https://5o3i5vmleh2hjizpqohakfngge0abcib.lambda-url.us-east-2.on.aws`,
        values
      )
      .then(() => {
        setStep(step + 1);
      });
  };

  return (
    <FormProvider {...methods}>
      <QuotationFormStyle onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <h2 className="form-heading">
          Get a Free
          <p>Instant Quote</p>
        </h2>

        <StepsStyle current={step} labelPlacement="vertical" items={items} />

        {step === 0 && <Step1 />}
        {step === 1 && <Step2 />}
        {step === 2 && <Step3 />}
        {step === 3 && <FinalStep />}

        {step !== LAST_STEP && (
          <Button
            label={step === LAST_STEP - 1 ? "Get a Quote" : "Next Step"}
            className="steps-button"
            type={ButtonType.filled}
          />
        )}
      </QuotationFormStyle>
    </FormProvider>
  );
};

export default QuotationForm;
