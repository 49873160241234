export const navItems = [
  {
      name: "Home",
      link: "/"
  },
  {
      name: "Services",
      link: "/services"
  },
  {
    name: "Door to door",
    link: "/door-to-door-car-transportion-service"
  },
  {
      name: "About Us",
      link: "/about-us"
  },
  {
      name: "Contact Us",
      link: "/contact-us"
  },
];
