import { Section } from "../common";
import { SectionType } from "../common/Section/Section";
import { AboutCompanyStyle } from "./AboutCompany.style";

const AboutCompany = () => {
  return (
    <Section title="About Company" type={SectionType.dark}>
      <AboutCompanyStyle>
        Addi Auto Transport is one of the largest automobile shippers inside the USA.
        We've got offices and garage centers positioned in important cities and
        metro areas in the course of the U.S, as well as a team of workers of
        experienced shipping experts to make sure you get the provider you want at
        a charge that you’ll love. This page is dedicated to assisting you
        recognize who we are and what we are all about.
      </AboutCompanyStyle>
    </Section>
  );
};

export default AboutCompany;
